<template>
    <div>
        <h3 v-for='(financialAccount, index) in financialAccounts' :key='index'>
            <div class='badge badge-light m-2'>
                {{ financialAccount.branch ? financialAccount.branch.name : '' }}
            </div>
            <div v-if='financialAccount.balance < 0' class='badge badge-danger'>
                {{ financialAccount.balance | currency}}
            </div>
            <div v-if='financialAccount.balance > 0' class='badge badge-success'>
                {{ financialAccount.balance | currency}}
            </div>
            <div v-if='financialAccount.balance == 0' class='badge badge-dark'>
                {{ 0 | currency }}
            </div>
        </h3>
    </div>
</template>

<script>
export default {
    props: ['financialAccounts']
}
</script>